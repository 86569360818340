var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.roles.length)?_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text-field, table, data-table"}})],1):_c('v-card',[_c('snack-view',{attrs:{"message":_vm.snackbar.message,"activate":_vm.snackbar.active,"bg-color":_vm.snackbar.color}}),_c('div',[_c('v-card-title',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" Liste des rôles ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Rechercher","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.roles,"search":_vm.search,"options":_vm.dataOptions,"items-per-page":15,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.name))])])])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(_vm.orderNum(item)))])])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(_vm.moment(item.created_at).format('D MMMM YYYY')))])])])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(_vm.moment(item.updated_at).format('D MMMM YYYY')))])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-12 w-"},[_c('v-btn',{attrs:{"outlined":"","x-small":"","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_vm._v(" Modifier ")],1),_c('v-btn',{staticClass:"ml-1",attrs:{"outlined":"","x-small":"","color":"success"},on:{"click":function($event){return _vm.goToEditRole(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_vm._v(" Affecter des permissions ")],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Modifier un rôle")])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"updateRole"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"2","md":"8"}},[_c('v-text-field',{attrs:{"label":"Nom","rules":_vm.LocalNameRules,"outlined":"","dense":"","placeholder":"Nom","hide-details":"auto"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.close}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }